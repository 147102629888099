




















































































.aboutShengrongAn {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .shell_2 {
    width: 100%;
    overflow: hidden;
    margin-top: 60px;
    .shell {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .p_2 {
        text-align: center;
        color: #5c6b84;
        font-size: 17px;
      }
      .d_1 {
        width: 23%;
        height: 288px;
        float: left;
        margin-right: 2%;
        margin-top: 40px;
        margin-bottom: 60px;
        border-radius: 6px;
        border: 1px solid #edf6ff;
        img {
          width: 48px;
          height: 48px;
          display: block;
          margin: auto;
          margin-top: 30px;
        }
        .p_1 {
          color: #3f4652;
          font-size: 20px;
          margin-top: 20px;
          font-weight: 500;
        }
        .p_2 {
          width: 86%;
          margin: auto;
          color: #5c6b84;
          font-size: 14px;
          margin-top: 16px;
          line-height: 20px;
          text-align: left;
        }
        &:hover {
          box-shadow: 0px 0px 38px 0px rgba(87, 174, 244, 0.16);
          cursor: pointer;
        }
      }
      .d_1_1 {
        margin-right: 0;
      }
      .s_1 {
        width: 1002px;
        height: 498px;
        display: block;
        margin: auto;
      }
      .p_3 {
        color: #5c6b84;
        font-size: 17px;
        margin-top: 30px;
        margin-bottom: 45px;
        line-height: 30px;
        text-align: left;
        text-indent: 2em;
        padding: 0 75px;
      }
    }
  }
  .shell_3 {
    width: 100%;
    overflow: hidden;
    padding: 60px 0;
    background: #f8fafd;
    .shell {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .s_1 {
        width: 100%;
        display: block;
        margin: auto;
      }
    }
  }
}
